import * as React from 'react';
import Navbar from '../components/navbar/Navbar';
import Wishlist from '../components/wishlist/Wishlist';
import '../styles/global.css';

const onskelistaPage = () => {
  return (
    <main>
      <title>Önskelista</title>
      <Navbar />
      <Wishlist />
    </main>
  )
}

export default onskelistaPage
